const ambulance = 'fas fa-ambulance';
const anchor = 'fas fa-anchor';
const artillery_left = 'icon-artillery-left';
const artillery_right = 'icon-artillery-right';
const atom = 'fas fa-atom';
const bahai = 'fas fa-bahai';
const bomb = 'icon-bomb';
const bullhorn = 'fas fa-bullhorn';
const chart_line = 'fas fa-chart-line';
const crosshairs = 'fas fa-crosshairs';
const drafting_compass = 'fas fa-drafting-compass';
const fire = 'fas fa-fire-alt';
const flag = 'fas fa-flag';
const gun_left = 'icon-gun-left';
const gun_right = 'icon-gun-right';
const helicopter = 'fas fa-helicopter';
const info = 'fas fa-info';
const plane = 'fas fa-plane';
const plane_slash = 'fas fa-plane-slash';
const reconnaissance = 'fas fa-binoculars';
const skull_crossbones = 'fas fa-skull-crossbones';
const tank_left = 'icon-tank-left';
const tank_right = 'icon-tank-right';
const truck = 'fas fa-truck';
const virus = 'fas fa-virus';

const orangeMarkerColor = '#ff981a';
const orangeMarkerStroke = '#e67e00';

const yellowMarkerColor = '';
const yellowMarkerStroke = '';

const blueMarkerColor = '#1500FF';
const blueMarkerStroke = '#150055';

const redMarkerColor = '#a50000';
const redMarkerStroke = '#800000';

const purpleMarkerColor = '#800080';
const purpleMarkerStroke = '#5f005f';

const greenMarkerColor = '#25790b';
const greenMarkerStroke = '#1e580d';

const blackMarkerColor = '#3f3f3f';
const blackMarkerStroke = '#262626';

const iconColor = '#FFF';
const markerStrokeWidth = 1;

module.exports = {
  black_ambulance: {
    iconClasses: ambulance,
    markerColor: blackMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blackMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  black_anchor: {
    iconClasses: anchor,
    markerColor: blackMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blackMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  black_artillery_left: {
    iconClasses: artillery_left,
    markerColor: blackMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blackMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  black_artillery_right: {
    iconClasses: artillery_right,
    markerColor: blackMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blackMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  black_atom: {
    iconClasses: atom,
    markerColor: blackMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blackMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  black_bahai: {
    iconClasses: bahai,
    markerColor: blackMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blackMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  black_bullhorn: {
    iconClasses: bullhorn,
    markerColor: blackMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blackMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  black_fire: {
    iconClasses: fire,
    markerColor: blackMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blackMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: 0,
    iconYOffset: 0,
  },
  black_gun_left: {
    iconClasses: gun_left,
    markerColor: blackMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blackMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  black_gun_right: {
    iconClasses: gun_right,
    markerColor: blackMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blackMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  black_info: {
    iconClasses: info,
    markerColor: blackMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blackMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  black_skull_crossbones: {
    iconClasses: skull_crossbones,
    markerColor: blackMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blackMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: 0,
    iconYOffset: 0,
  },
  black_tank_left: {
    iconClasses: tank_left,
    markerColor: blackMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blackMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  black_tank_right: {
    iconClasses: tank_right,
    markerColor: blackMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blackMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  black_truck: {
    iconClasses: truck,
    markerColor: blackMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blackMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  black_virus: {
    iconClasses: virus,
    markerColor: blackMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blackMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  black_flag: {
    iconClasses: flag,
    markerColor: blackMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blackMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: 0,
    iconYOffset: 0,
  },
  black_plane: {
    iconClasses: plane,
    markerColor: blackMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blackMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1.5,
    iconYOffset: 0,
  },
  black_plane_slash: {
    iconClasses: plane_slash,
    markerColor: blackMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blackMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -3,
    iconYOffset: 0,
  },
  black_chart_line: {
    iconClasses: chart_line,
    markerColor: blackMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blackMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: 0,
    iconYOffset: 0,
  },
  black_drafting_compass: {
    iconClasses: drafting_compass,
    markerColor: blackMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blackMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: 0,
    iconYOffset: 0,
  },
  black_bomb: {
    iconClasses: bomb,
    markerColor: blackMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blackMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },

  red_ambulance: {
    iconClasses: anchor,
    markerColor: redMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: redMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  red_anchor: {
    iconClasses: anchor,
    markerColor: redMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: redMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  red_artillery_left: {
    iconClasses: artillery_left,
    markerColor: redMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: redMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  red_artillery_right: {
    iconClasses: artillery_right,
    markerColor: redMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: redMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  red_bahai: {
    iconClasses: bahai,
    markerColor: redMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: redMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  red_bomb: {
    iconClasses: bomb,
    markerColor: redMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: redMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  red_bullhorn: {
    iconClasses: bullhorn,
    markerColor: redMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: redMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  red_chart_line: {
    iconClasses: chart_line,
    markerColor: redMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: redMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: 0,
    iconYOffset: 0,
  },
  red_crosshairs: {
    iconClasses: crosshairs,
    markerColor: redMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: redMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  red_drafting_compass: {
    iconClasses: drafting_compass,
    markerColor: redMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: redMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  red_fire: {
    iconClasses: fire,
    markerColor: redMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: redMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: 0,
    iconYOffset: 0,
  },
  red_flag: {
    iconClasses: flag,
    markerColor: redMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: redMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  red_gun_left: {
    iconClasses: gun_left,
    markerColor: redMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: redMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  red_gun_right: {
    iconClasses: gun_right,
    markerColor: redMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: redMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  red_info: {
    iconClasses: info,
    markerColor: redMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: redMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  red_plane: {
    iconClasses: plane,
    markerColor: redMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: redMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2,
    iconYOffset: 0,
  },
  red_plane_slash: {
    iconClasses: plane_slash,
    markerColor: redMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: redMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1.5,
    iconYOffset: 0
  },
  red_reconnaissance: {
    iconClasses: reconnaissance,
    markerColor: redMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: redMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0
  },
  red_skull_crossbones: {
    iconClasses: skull_crossbones,
    markerColor: redMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: redMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: 0,
    iconYOffset: 0,
  },
  red_tank_left: {
    iconClasses: tank_left,
    markerColor: redMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: redMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  red_tank_right: {
    iconClasses: tank_right,
    markerColor: redMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: redMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  red_truck: {
    iconClasses: truck,
    markerColor: redMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: redMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  red_helicopter: {
    iconClasses: helicopter,
    markerColor: redMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: redMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -4,
    iconYOffset: 0,
  },

  green_ambulance: {
    iconClasses: ambulance,
    markerColor: greenMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: greenMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  green_anchor: {
    iconClasses: anchor,
    markerColor: greenMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: greenMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  green_bahai: {
    iconClasses: bahai,
    markerColor: greenMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: greenMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  green_drafting_compass: {
    iconClasses: drafting_compass,
    markerColor: greenMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: greenMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  green_fire: {
    iconClasses: fire,
    markerColor: greenMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: greenMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: 0,
    iconYOffset: 0,
  },
  green_info: {
    iconClasses: info,
    markerColor: greenMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: greenMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  green_artillery_left: {
    iconClasses: artillery_left,
    markerColor: greenMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: greenMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  green_artillery_right: {
    iconClasses: artillery_right,
    markerColor: greenMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: greenMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  green_bullhorn: {
    iconClasses: bullhorn,
    markerColor: greenMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: greenMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  green_chart_line: {
    iconClasses: chart_line,
    markerColor: greenMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: greenMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  green_gun_left: {
    iconClasses: gun_left,
    markerColor: greenMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: greenMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  green_gun_right: {
    iconClasses: gun_right,
    markerColor: greenMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: greenMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  green_skull_crossbones: {
    iconClasses: skull_crossbones,
    markerColor: greenMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: greenMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: 0,
    iconYOffset: 0,
  },
  green_flag: {
    iconClasses: flag,
    markerColor: greenMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: greenMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: 0,
    iconYOffset: 0,
  },
  green_truck: {
    iconClasses: truck,
    markerColor: greenMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: greenMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  green_tank_left: {
    iconClasses: tank_left,
    markerColor: greenMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: greenMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  green_tank_right: {
    iconClasses: tank_right,
    markerColor: greenMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: greenMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  green_bomb: {
    iconClasses: bomb,
    markerColor: greenMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: greenMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1.5,
    iconYOffset: 0,
  },
  green_plane: {
    iconClasses: plane,
    markerColor: greenMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: greenMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1.5,
    iconYOffset: 0,
  },
  green_plane_slash: {
    iconClasses: plane_slash,
    markerColor: greenMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: greenMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1.5,
    iconYOffset: 0
  },
  green_helicopter: {
    iconClasses: helicopter,
    markerColor: greenMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: greenMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -4,
    iconYOffset: 0,
  },
  green_reconnaissance: {
    iconClasses: reconnaissance,
    markerColor: greenMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: greenMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },

  purple_gun_right: {
    iconClasses: gun_right,
    markerColor: purpleMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: purpleMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  purple_bullhorn: {
    iconClasses: bullhorn,
    markerColor: purpleMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: purpleMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  purple_flag: {
    iconClasses: flag,
    markerColor: purpleMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: purpleMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1.5,
    iconYOffset: 0,
  },
  purple_truck: {
    iconClasses: truck,
    markerColor: purpleMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: purpleMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },

  orange_ambulance: {
    iconClasses: ambulance,
    markerColor: orangeMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: orangeMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  orange_bahai: {
    iconClasses: bahai,
    markerColor: orangeMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: orangeMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  orange_bullhorn: {
    iconClasses: bullhorn,
    markerColor: orangeMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: orangeMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  orange_drafting_compass: {
    iconClasses: drafting_compass,
    markerColor: orangeMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: orangeMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  orange_flag: {
    iconClasses: flag,
    markerColor: orangeMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: orangeMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: 0,
    iconYOffset: 0,
  },
  orange_info: {
    iconClasses: info,
    markerColor: orangeMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: orangeMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0
  },
  orange_gun_left: {
    iconClasses: gun_left,
    markerColor: orangeMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: orangeMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  orange_gun_right: {
    iconClasses: gun_right,
    markerColor: orangeMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: orangeMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  orange_truck: {
    iconClasses: truck,
    markerColor: orangeMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: orangeMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },

  blue_ambulance: {
    iconClasses: ambulance,
    markerColor: blueMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blueMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  blue_anchor: {
    iconClasses: anchor,
    markerColor: blueMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blueMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  blue_artillery_left: {
    iconClasses: artillery_left,
    markerColor: blueMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blueMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: 0,
    iconYOffset: 0,
  },
  blue_artillery_right: {
    iconClasses: artillery_right,
    markerColor: blueMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blueMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: 0,
    iconYOffset: 0,
  },
  blue_flag: {
    iconClasses: flag,
    markerColor: blueMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blueMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: 0,
    iconYOffset: 0,
  },
  blue_gun_left: {
    iconClasses: gun_left,
    markerColor: blueMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blueMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  blue_gun_right: {
    iconClasses: gun_right,
    markerColor: blueMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blueMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  blue_bomb: {
    iconClasses: bomb,
    markerColor: blueMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blueMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  blue_chart_line: {
    iconClasses: chart_line,
    markerColor: blueMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blueMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: 0,
    iconYOffset: 0,
  },
  blue_crosshairs: {
    iconClasses: crosshairs,
    markerColor: blueMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blueMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0
  },
  blue_truck: {
    iconClasses: truck,
    markerColor: blueMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blueMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  blue_info: {
    iconClasses: info,
    markerColor: blueMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blueMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  blue_bullhorn: {
    iconClasses: bullhorn,
    markerColor: blueMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blueMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -2.5,
    iconYOffset: 0,
  },
  blue_bahai: {
    iconClasses: bahai,
    markerColor: blueMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blueMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  blue_drafting_compass: {
    iconClasses: drafting_compass,
    markerColor: blueMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blueMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  blue_plane: {
    iconClasses: plane,
    markerColor: blueMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blueMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1.5,
    iconYOffset: 0,
  },
  blue_plane_slash: {
    iconClasses: plane_slash,
    markerColor: blueMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blueMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -3,
    iconYOffset: 0
  },
  blue_reconnaissance: {
    iconClasses: reconnaissance,
    markerColor: blueMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blueMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0
  },
  blue_fire: {
    iconClasses: fire,
    markerColor: blueMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blueMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  blue_skull_crossbones: {
    iconClasses: skull_crossbones,
    markerColor: blueMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blueMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: 0,
    iconYOffset: 0,
  },
  blue_tank_left: {
    iconClasses: tank_left,
    markerColor: blueMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blueMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  blue_tank_right: {
    iconClasses: tank_right,
    markerColor: blueMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blueMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -1,
    iconYOffset: 0,
  },
  blue_helicopter: {
    iconClasses: helicopter,
    markerColor: blueMarkerColor,
    markerStrokeWidth: markerStrokeWidth,
    markerStrokeColor: blueMarkerStroke,
    iconColor: iconColor,
    iconSize: [15, 15],
    iconXOffset: -4,
    iconYOffset: 0,
  }
};
