import React, { ReactNode } from 'react';
import Tooltip from '@mui/material/Tooltip';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { useTranslation } from 'react-i18next';
import { InfoCardModel } from '@/models/sidebar/infoCard.model';
import { SourceType } from '@/models/sourceTypes.enum';
import '@/styles/sidebar.css';
import { isBrowser } from 'react-device-detect';
import { ClickAwayListener } from '@mui/material';

export function InfoCard(props: InfoCardModel): ReactNode {
  const [showSource, setShowSource] = React.useState<boolean>(false);

  const { t } = useTranslation();
  const sourceRef = props.sources.find((s) => s._id == props.source);

  function createTitle(): JSX.Element {
    if (!sourceRef) {
      return (<></>);
    }

    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const sourceMonthIndex = Number(sourceRef.accessDate.substring(5, 7).replace('0', '')) - 1;

    switch (sourceRef.type) {
      case SourceType.book:
        return (
          <span className='tool-tip'>
            {sourceRef.author}. <b>"{sourceRef.title}"</b> <i>{sourceRef.publisher}</i>, {sourceRef.publishDate.substring(0, 4)}.
          </span>
        );
      case SourceType.journal:
        return (
          <span className='tool-tip'>
            {sourceRef.author}. <b>"{sourceRef.title}"</b> <i>{sourceRef.publisher}</i>, {sourceRef.publishDate.substring(0, 4)}.
          </span>
        );
      case SourceType.newsPaper:
        return (
          <span className='tool-tip'>
            <b>"{sourceRef.title}"</b>. <i>{sourceRef.publisher}</i>, {sourceRef.publishDate.substring(0, 4)}.
          </span>
        );
      case SourceType.website:
        return (
          <span className='tool-tip'>
            <b>"{sourceRef.title}"</b> {sourceRef.author}, {sourceRef.publisher}, {
              sourceRef.accessDate.substring(8, 10) + ' ' + monthNames[sourceMonthIndex] + ' ' + sourceRef.accessDate.substring(0, 4)
            }, <a target="_blank" href={sourceRef.url}>{sourceRef.url}</a>
          </span>
        );
      default: return (<></>);
    }
  }

  function setView() {
    props.mapFunction(props.location);
  }

  function toggleTooltip() {
    setShowSource(!showSource);
  }

  function closeTooltip() {
    setShowSource(false);
  }

  return (
    <div>
      <div id={props.id} className="infoCard">
        <i
          className={props.icon + ' circle-fa'}
          onClick={setView}
        />
        <b className="country-name">{props.country}</b>
        <div className="flag-right">
          <img className="actual-flag" src={props.flag} alt="404" />
        </div>
        {sourceRef !== null ?
          <div className="info-content">
            {props.content}
            {isBrowser ?
              <Tooltip title={createTitle()} arrow placement="top" componentsProps={{
                tooltip: {
                  sx: {
                    top: '10px',
                    bgcolor: '#000000',
                    '& .MuiTooltip-arrow': {
                      color: '#000000'
                    }
                  }
                }
              }}>
                <a
                  href="#"
                  className="read-more-info"
                  data-toggle="tooltip"
                  data-html="true"
                  data-container="body"
                  data-placement="top"
                  title=""
                  data-original-title={createTitle()}
                  style={{ marginLeft: '5px', marginRight: '5px' }}
                >
                  {t('sidebar.source')}
                  <OpenInNewRoundedIcon sx={{ fontSize: 'large', position: 'relative', top: '1px' }} />
                </a>
              </Tooltip>
              :
              <ClickAwayListener onClickAway={closeTooltip}>
                <Tooltip open={showSource} onClick={toggleTooltip} title={createTitle()} arrow placement="top" componentsProps={{
                  tooltip: {
                    sx: {
                      top: '10px',
                      bgcolor: '#000000',
                      '& .MuiTooltip-arrow': {
                        color: '#000000'
                      }
                    }
                  }
                }}>
                  <a
                    href="#"
                    className="read-more-info"
                    data-toggle="tooltip"
                    data-html="true"
                    data-container="body"
                    data-placement="top"
                    title=""
                    data-original-title={createTitle()}
                    style={{ marginLeft: '5px', marginRight: '5px' }}
                  >
                    {t('sidebar.source')}
                    <OpenInNewRoundedIcon sx={{ fontSize: 'large', position: 'relative', top: '1px' }} />
                  </a>
                </Tooltip>
              </ClickAwayListener>
            }
          </div>
          : null
        }
      </div>
    </div>
  );
}