import React from 'react';

export function BombIcon(props: {width: number, height: number, color: string}): JSX.Element {
  const { width, height, color } = props;

  return (
    <svg
      id="svg"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0, 0, 400,400"
    >
      <g id="svgg">
        <path
          id="path0"
          d="M0.000 200.000 L 0.000 400.000 200.000 400.000 L 400.000 400.000 400.000 200.000 L 400.000 0.000 200.000 0.000 L 0.000 0.000 0.000 200.000 M199.421 4.990 C 201.467 6.013,205.783 10.201,211.180 16.400 C 212.808 18.270,216.063 21.870,218.412 24.400 C 220.762 26.930,223.816 30.325,225.199 31.945 C 226.582 33.564,229.271 36.534,231.174 38.545 C 233.077 40.555,235.873 43.640,237.388 45.400 C 244.379 53.524,255.803 65.463,257.366 66.280 C 260.028 67.670,268.519 63.486,285.756 52.290 C 301.293 42.197,304.614 45.950,299.481 67.800 C 294.941 87.125,294.838 88.591,297.880 90.634 C 299.858 91.962,312.008 95.435,350.400 105.646 C 384.615 114.746,384.891 114.877,384.318 121.784 C 384.055 124.953,384.172 124.781,361.638 155.200 C 342.651 180.831,349.196 178.159,306.166 177.852 C 275.324 177.633,274.146 177.685,273.012 179.305 C 272.104 180.601,272.230 181.689,274.166 189.278 C 277.907 203.947,277.705 213.894,273.388 227.527 C 270.367 237.066,266.193 243.944,252.585 261.800 C 249.902 265.320,246.288 270.203,244.554 272.652 C 241.055 277.590,233.507 287.845,223.983 300.600 C 220.534 305.220,213.694 314.490,208.785 321.200 C 203.875 327.910,197.617 336.460,194.878 340.200 C 188.133 349.408,185.125 352.913,180.296 357.191 C 158.830 376.205,129.941 382.685,100.228 375.151 C 93.634 373.479,92.704 373.603,87.767 376.801 C 66.884 390.329,41.147 374.973,46.200 352.000 C 47.403 346.529,47.008 345.456,41.162 338.312 C 27.867 322.067,22.878 304.813,25.574 284.400 C 27.643 268.742,30.474 263.675,57.200 227.800 C 66.251 215.651,75.217 203.486,80.193 196.600 C 82.578 193.300,86.363 188.170,88.605 185.200 C 90.846 182.230,95.529 175.930,99.011 171.200 C 113.480 151.545,117.135 146.997,121.037 143.784 C 137.885 129.910,146.916 126.016,168.761 123.205 C 179.636 121.805,179.635 122.810,168.791 100.200 C 157.406 76.461,156.778 75.130,154.802 70.560 C 150.030 59.525,148.732 62.258,176.567 24.747 C 179.445 20.868,183.236 15.738,184.990 13.347 C 191.775 4.101,194.496 2.526,199.421 4.990 "
          stroke="none"
          fill="#fcfcfc"
          fillRule="evenodd"
        />
        <path
          id="path1"
          d="M194.200 9.267 C 192.252 10.344,191.804 10.922,171.628 38.352 C 168.453 42.669,164.266 48.360,162.322 51.000 C 154.366 61.804,154.167 60.228,166.821 86.600 C 181.513 117.219,182.035 118.387,182.293 121.213 C 182.665 125.282,180.654 126.553,173.200 126.963 C 147.982 128.347,128.143 138.806,113.565 158.400 C 111.683 160.930,107.019 167.230,103.201 172.400 C 99.382 177.570,93.440 185.580,89.995 190.200 C 86.550 194.820,82.172 200.760,80.268 203.400 C 74.648 211.189,72.297 214.383,63.195 226.600 C 37.848 260.622,35.306 264.513,32.065 274.246 C 24.882 295.821,29.535 317.947,45.233 336.863 C 51.534 344.456,51.880 345.466,50.444 352.093 C 45.928 372.929,68.064 385.513,86.527 372.606 C 91.438 369.174,92.826 369.012,100.451 370.983 C 134.944 379.898,168.271 369.152,187.859 342.800 C 193.000 335.884,202.978 322.323,209.198 313.800 C 212.570 309.180,218.663 300.900,222.739 295.400 C 226.814 289.900,232.149 282.700,234.594 279.400 C 237.039 276.100,240.190 271.780,241.596 269.800 C 243.002 267.820,246.377 263.275,249.094 259.700 C 273.589 227.479,277.235 213.643,268.770 185.048 C 267.273 179.991,267.238 177.691,268.635 176.032 C 270.739 173.531,270.406 173.552,306.266 173.664 C 346.827 173.791,341.593 175.235,352.197 161.000 C 378.110 126.216,379.580 124.100,379.593 121.554 C 379.612 117.745,378.994 117.497,350.800 110.001 C 305.021 97.831,295.339 94.939,293.199 92.799 C 290.297 89.897,290.390 88.903,295.783 65.200 C 297.920 55.810,297.601 52.800,294.468 52.800 C 293.047 52.800,291.622 53.552,282.200 59.270 C 260.125 72.668,259.488 72.928,255.279 70.239 C 253.213 68.918,243.883 59.013,233.200 46.799 C 231.330 44.661,228.055 41.041,225.923 38.756 C 223.791 36.470,220.731 33.126,219.123 31.325 C 199.071 8.860,197.481 7.452,194.200 9.267 "
          stroke="none"
          fill={color}
          fillRule="evenodd"
        />
        <path id="path2" d="" stroke="none" fill="#bc10080" fillRule="evenodd" />
        <path id="path3" d="" stroke="none" fill="#bc10080" fillRule="evenodd" />
        <path id="path4" d="" stroke="none" fill="#bc10080" fillRule="evenodd" />
      </g>
    </svg>
  );
}