module.exports = {
  neutral_zone: 252,
  neutral: 253,
  arabs_occupied: 254,
  arabs_puppet: 255,
  arabs: 256,
  jews_occupied: 257,
  jews_puppet: 258,
  jews: 259,
  uk_occupied: 260,
  uk_puppet: 261,
  uk: 262,
  dmz: 263,
  labels: 265,
  allies_occupied: 266,
  allies_puppet: 267,
  allies: 268,
};
