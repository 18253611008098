<template>
  <div id="container">
    <!-- <div v-if="!ready" id="loading">
      <loader></loader>
    </div> -->
    <div id="mapContainer" class="map"></div>
    <DateChanger
      v-show="showDateChanger"
      :date="this.$store.state.date"
      :lang="this.$store.state.locale"
      :backDate="handleBackDate"
      :nextDate="handleNextDate"
    />
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import '@/assets/css/map.css';
import '@/assets/css/pie.css';
import L from 'leaflet';
import 'leaflet.pattern';
/* import config from '@/config.json'; */
import mapConfig from '@/map.json';
import GeoJsonParser from '@/helpers/geoJson.js';
import panes from '@/helpers/panes.js';
import onEachFeature from '@/helpers/onEachFeature.js';
import effects from '@/helpers/effect.js';
import markers from '@/helpers/marker.js';
import placesFunc from '@/helpers/place.js';
import iconMarker from '@/helpers/icons.js';
import '@/assets/leaflet/L.Icon.FontAwesome.js';
import '@/assets/leaflet/L.Icon.FontAwesome.css';
import '@/assets/fonts/customIcon.css';
import 'leaflet-easybutton/src/easy-button.css';
import 'leaflet-easybutton/src/easy-button.js';
import 'leaflet.polylinemeasure/Leaflet.PolylineMeasure.css';
import 'leaflet.polylinemeasure/Leaflet.PolylineMeasure.js';
import hotkeys from 'hotkeys-js';
import * as indexDb from 'localforage';
import { DateChanger } from '@/components/DateChanger'
import { applyPureReactInVue } from 'veaury';

import db1948 from '@/helpers/blob-1948.json';
import db1956 from '@/helpers/blob-1956.json';

/*
Bc of reactive changes window.map will be used instead of this.map
else vuejs would reproxy the map object,which causes a lot of problems
*/

export default {
  name: 'Map',
  data() {
    return {
      dbSources: [],
      dbGeos: [],
      dbEvents: [],
      dbCountries: [],
      dbDates: [],
      bounds: null,
      minZoom: 8,
      maxZoom: 14,
      effect: {},
      conflicts: [
        {value: '1948-arab-israeli-war', name: '1948 Arab-Israeli War'},
        {value: 'suez-crisis', name: '1956 Suez Crisis'},
      ],
      ready: false,
      attribution:
        'Map data (c) <a href="https://www.openstreetmap.org/">OpenStreetMap</a> & <a href="https://projectwaw.org">P:WaW</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery (c) <a href="https://www.mapbox.com/">Mapbox</a>',
      showDateChanger: true,
      previousWar: null,
    };
  },
  components: {
    DateChanger: applyPureReactInVue(DateChanger)
  },
  computed: {
    currentConflict() {
      const conflictParam = this.$route.params.conflict;
      return this.conflicts.find(conflict => conflict.value === conflictParam) || {};
    }
  },
  methods: {
    checkCurrentWar: function () {
      switch (this.$store.state.war) {
        case '1948-arab-israeli-war':
          this.dbSources = db1948.sources;
          this.dbGeo = db1948.geo;
          this.dbEvents = db1948.events;
          this.dbCountries = db1948.countries;
          this.dbDates = db1948.dates;
          this.bounds = new L.LatLngBounds(new L.LatLng(27.352253, 31.651611), new L.LatLng(34.316218, 42.451172));
          break;
        case 'suez-crisis':
          this.dbSources = db1956.sources;
          this.dbGeo = db1956.geo;
          this.dbEvents = db1956.events;
          this.dbCountries = db1956.countries;
          this.dbDates = db1956.dates;
          this.bounds = new L.LatLngBounds(new L.LatLng(24.437147, 27.410888), new L.LatLng(35.764345, 37.287599));
          break;
      }
    },
    async updateCache() {
      const currentWar = this.$store.state.war;
      
      this.geoMap = (await indexDb.getItem('map')) || [];
      const expired = (await indexDb.getItem('expired')) > Date.now() || false;
      if (!this.geoMap.length || expired || this.previousWar !== currentWar) {
        // const res = await fetch(config.api + '/geo');
        // this.geoMap = await res.json();
        this.geoMap = this.dbGeo;
        await indexDb.setItem('map', JSON.stringify(this.geoMap));
        await indexDb.setItem('expiry', Date.now() + 1000 * 60 * 60 * 24 * 7);
      } else {
        this.geoMap = JSON.parse(this.geoMap);
      }

      this.dateRelation = (await indexDb.getItem('dateRelation')) || [];
      if (!this.dateRelation.length || expired || this.previousWar !== currentWar) {
        // const res = await fetch(config.api + '/dates');
        // this.dateRelation = await res.json();
        this.dateRelation = this.dbDates;
        await indexDb.setItem('dateRelation', JSON.stringify(this.dateRelation));
      } else {
        this.dateRelation = JSON.parse(this.dateRelation);
      }
      
      // cache events
      this.events = (await indexDb.getItem('events')) || [];
      if (!this.events.length || expired || this.previousWar !== currentWar) {
        // const res = await fetch(config.api + '/events');
        // this.events = await res.json();
        this.events = this.dbEvents;
        await indexDb.setItem('events', JSON.stringify(this.events));
      } else {
        this.events = JSON.parse(this.events)
      }
      
      // cache countries
      this.countries = (await indexDb.getItem('countries')) || [];
      if (!this.countries.length || expired || this.previousWar !== currentWar) {
        // const res = await fetch(config.api + '/countries');
        // this.countries = await res.json();
        this.countries = this.dbCountries
        await indexDb.setItem('countries', JSON.stringify(this.countries));
      } else {
        this.countries = JSON.parse(this.countries)
      }
      this.previousWar = currentWar;
    },
    setupLeafletMap: function () {
      const bounds = this.bounds;
      window.map = L.map('mapContainer', {
        center: bounds.getCenter(),
        zoom: 8,
        maxBounds: bounds,
        maxBoundsViscosity: 1,
      });

      this.layer = L.tileLayer(mapConfig.normal, {
        attribution: this.attribution,
        minZoom: this.minZoom,
        maxZoom: this.maxZoom,
      });

      this.geolayer = L.tileLayer(mapConfig.satellite, {
        attribution: this.attribution,
        minZoom: this.minZoom,
        maxZoom: this.maxZoom,
      });

      if (this.$store.state.geo) {
        this.geolayer.addTo(window.map);
      }
      else {
        this.layer.addTo(window.map);
      }
    },
    addLabels: function() {
      const labels = L.tileLayer(mapConfig.labels, {
        attribution: this.attribution,
        minZoom: this.minZoom,
        pane: 'labels',
        maxZoom: this.maxZoom,
      });
      labels.addTo(window.map);
    },
    renderGeoJson() {
      const parsed = GeoJsonParser.parse(this.geoMap, this.dateRelation, this.$store.state.date);
      for (const country of parsed) {
        const isLine = country.properties.country.includes('commando');
        const countryLayer = L.layerGroup();
        const geoJsonLayer = L.geoJSON(country, {
          style: {
            color: country.properties.color,
            fillPattern: this.effect[country.properties.effect],
            zIndex: 260,
            weight: isLine ? 3 : 1,
          },
          pane: country.properties.pane,
          onEachFeature: onEachFeature._,
        });
        geoJsonLayer.addTo(countryLayer);
        window.map.addLayer(countryLayer);
      }
      // window.map = window.map;
    },
    setupPanes() {
      for (const [key, value] of Object.entries(panes)) {
        window.map.createPane(key);
        window.map.getPane(key).style.zIndex = value;
      }
    },
    setupEvents() {
      function onMapClick(e) {
        popup.setLatLng(e.latlng).setContent(e.latlng.toString()).openOn(window.map);
      }
      const popup = L.popup();
      window.map.on('click', onMapClick);
    },
    setupMarker() {
      this.markerGroup = new L.featureGroup();
      const markerMap = {};
      for (const marker of markers(this.$store.state.date, this.dbEvents)) {
        markerMap[marker.id] = new L.Marker(marker.location, {
          id: marker.id + '_',
          tag: 'marker',
          title: this.$store.state.locale === 'en' ? marker.text : marker['text_' + this.$store.state.locale],
          icon: L.icon.fontAwesome(iconMarker[marker.marker]),
        });
        if (!iconMarker[marker.marker]) {
          console.error(marker.marker + ' is not a defined marker in icons.js');
        }
        this.markerGroup.addLayer(markerMap[marker.id]);
        markerMap[marker.id].on('click', () => {
          this.$store.commit('setSidebar', 'info');
          this.$store.commit('setView', 'sidebar');
          // on rendered
          setTimeout(() => {
            location.href = '#' + marker.id + '';
          }, 1000);
          location.href = '#' + marker.id + '';
          // remove highlighting
          setTimeout(() => {
            location.href = '#';
          }, 5000);
        });
      }
      if (!this.$store.state.marker) {
        return;
      }
      window.map.addLayer(this.markerGroup);
    },
    setupPlaces() {
      this.placesGroup = new L.featureGroup();
      const placesMap = {};
      for (const place of placesFunc._(this.places)) {
        if (!place.id) {
          continue;
        }
        placesMap[place.id] = new L.circle(place.location, {
          color: place.color,
          fillColor: place.color,
          fillOpacity: 0.2,
          radius: place.radius,
        });
        placesMap[place.id].bindPopup(place.popup, {
          maxWidth: 'auto',
        });
        placesMap[place.id].bindTooltip(place.tooltip);

        this.placesGroup.addLayer(placesMap[place.id]);
      }
      if (!this.$store.state.place) {
        return;
      }
      window.map.addLayer(this.placesGroup);
    },
    setupPatterns() {
      for (const effect of effects) {
        this.effect[effect.name] = new L.StripePattern(effect.style);
        this.effect[effect.name].addTo(window.map);
      }
    },
    clearLayers() {
      window.map.eachLayer((layer) => {
        if (layer.options && layer.options.tag === 'marker') {
          window.map.removeLayer(layer);
        }
        if (layer.tag == 'geo') {
          window.map.removeLayer(layer);
        }
      });
      // window.map.removeLayer(this.markerGroup);
    },
    setupButtons() {
      L.control.scale({ position: this.$store.state.locale == 'he' || this.$store.state.locale == 'ar' ? 'bottomleft' : 'bottomright' }).addTo(window.map);
      window.map.zoomControl.setPosition(this.$store.state.locale == 'he' || this.$store.state.locale == 'ar' ? 'bottomright' : 'bottomleft');

      /* L.easyButton({
        id: 'toggle-places',
        position: 'bottomleft',
        type: 'replace',
        leafletClasses: true,
        states: [
          {
            stateName: 'get-center',
            onClick: (button, map) => {
              this.$store.commit('togglePlace');
              if (this.$store.state.place) {
                window.map.addLayer(this.placesGroup);
              } else {
                window.map.removeLayer(this.placesGroup);
              }
            },
            title: 'Hide / show places on the map',
            icon: '<i class="fa-solid fa-house" style="font-size:17px;"></i>',
          },
        ],
      }).addTo(window.map);*/

      L.control.polylineMeasure({ position: this.$store.state.locale == 'he' || this.$store.state.locale == 'ar' ? 'bottomright' : 'bottomleft' }).addTo(window.map);
      L.easyButton({
        id: 'toggle-markers',
        position: this.$store.state.locale == 'he' || this.$store.state.locale == 'ar' ? 'bottomright' : 'bottomleft',
        type: 'replace',
        leafletClasses: true,
        states: [
          {
            stateName: 'get-center',
            onClick: (button, map) => {
              this.$store.commit('toggleMarker');
              if (this.$store.state.marker) {
                window.map.addLayer(this.markerGroup);
              }
              else {
                window.map.removeLayer(this.markerGroup);
              }
            },
            title: 'Hide / show events on the map',
            icon: '<i class="fas fa-map-marker-alt" style="font-size:18px;margin-left:2px;"></i>',
          },
        ],
      }).addTo(window.map);

      /* L.easyButton({
        id: 'change-night',
        position: 'bottomleft',
        type: 'replace',
        leafletClasses: true,
        states: [
          {
            stateName: 'get-center',
            onClick: () => {
              this.$store.commit('toggleTheme');
            },
            title: 'Toggle between the light mode and the dark mode',
            icon: '<i class="fas fa-moon" style="font-size:18px;"></i>',
          },
        ],
      }).addTo(window.map);*/

      L.easyButton({
        id: 'change-map',
        position: this.$store.state.locale == 'he' || this.$store.state.locale == 'ar' ? 'bottomright' : 'bottomleft',
        type: 'replace',
        leafletClasses: true,
        states: [
          {
            stateName: 'get-center',
            onClick: (button, map) => {
              this.$store.commit('toggleGeo');
              if (this.$store.state.geo) {
                window.map.addLayer(this.geolayer);
                window.map.removeLayer(this.layer);
              }
              else {
                window.map.removeLayer(this.geolayer);
                window.map.addLayer(this.layer);
              }
            },
            title: 'Toggle between the political map and the satellite map',
            icon: '<i class="fas fa-satellite" style="font-size:18px;"></i>',
          },
        ],
      }).addTo(window.map);

      L.easyButton({
        id: 'change-date',
        position: this.$store.state.locale == 'he' || this.$store.state.locale == 'ar' ? 'bottomright' : 'bottomleft',
        type: 'replace',
        leafletClasses: true,
        states: [
          {
            stateName: 'get-center',
            onClick: () => {
              this.showDateChanger = !this.showDateChanger;
            },
            title: 'Hide / show the date changer',
            icon: '<i class="fas fa-calendar-week" style="font-size:18px;"></i>',
          },
        ],
      }).addTo(window.map);
    },
    setupKeys() {
      hotkeys('ctrl+.', (event, handler) => {
        event.preventDefault();
        this.$store.commit('nextDate');
      });
      hotkeys('ctrl+,', (event, handler) => {
        event.preventDefault();
        this.$store.commit('backDate');
      });
    },
    async setLayout() {
      this.checkCurrentWar()
      await this.updateCache()
      let mode = 'ltr';
      if (this.$i18n.locale == 'en' || this.$i18n.locale == 'hr') {
        mode = 'ltr';
      }
      else if (this.$i18n.locale == 'ar' || this.$i18n.locale == 'he') {
        mode = 'rtl';
      }
      document.documentElement.setAttribute('dir', mode);
      // refresh locale and layout
      onEachFeature.cache(this.countries, this.$i18n.messages[this.$i18n.locale]?.popupcountry || this.$i18n.messages['en']?.popupcountry, this.$store.state.locale, mode);

      placesFunc.cache(this.$i18n.messages[this.$i18n.locale]?.popupplace || this.$i18n.messages['en']?.popupplace, mode);
      this.clearLayers();
      this.renderGeoJson();
      this.setupMarker();
    },
    getLayout() {
      const rtl = document.documentElement.getAttribute('dir') === 'rtl';
      return rtl ? 'rtl' : 'ltr';
    },
    handleBackDate() {
      this.$store.commit('backDate');
    },
    handleNextDate() {
      this.$store.commit('nextDate');
    }
  },
  async mounted() {
    this.checkCurrentWar()
    // locales
    this.$i18n.locale = this.$store.state.locale;
    await this.updateCache()
    
    // cache places
    this.places = [];
    /* this.places = (await indexDb.getItem('places')) || [];
    if (!this.places.length || expired) {
      const res = await fetch(config.api + '/places');
      this.places = await res.json();
      await indexDb.setItem('places', this.places);
    } */

    onEachFeature.cache(this.countries, this.$i18n.messages[this.$i18n.locale]?.popupcountry || this.$i18n.messages['en']?.popupcountry, this.$store.state.locale, this.getLayout());
    placesFunc.cache(this.$i18n.messages[this.$i18n.locale]?.popupplace || this.$i18n.messages['en']?.popupplace, this.getLayout());

    // setup leaflet map
    this.setupKeys();
    this.setupLeafletMap();
    this.setupPanes();
    this.addLabels();
    this.setupPatterns();
    // this.setupEvents();
    this.setupMarker();
    this.setupPlaces();
    this.renderGeoJson();
    this.setupButtons();
    this.$store.commit('setReady', true);

    // watch for changes in the store
    this.$store.watch(
      (state) => {
        return this.$store.state.date;
      },
      (newValue, oldValue) => {
        this.clearLayers();
        this.renderGeoJson();
        this.setupMarker();
      }
    );

    this.$store.watch(
      (state) => {
        return this.$store.state.resize;
      },
      (newValue, oldValue) => {
        window.map.invalidateSize();
      }
    );

    this.$watch(
      '$i18n.locale',
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return;
        }
        this.$store.commit('setLocale', newLocale);
        this.setLayout();
      },
      { immediate: true }
    );

    this.$watch(
      '$store.state.war',
      (newWar, oldWar) => {
        if (newWar === oldWar) {
          return;
        }
        this.$store.commit('changeConflict', newWar);
        this.checkCurrentWar()
        this.setLayout();
      },
      { immediate: true }
    );
  },
  unmounted() {
    console.log('unmounted');
    this.clearLayers();
    window.map.remove();
    hotkeys.unbind();
  },
};
</script>

<style lang="scss" scoped>
.map {
  position: fixed;
  overflow: hidden !important;
  min-height: 100vh;
  bottom: 0;
  left: 0;
  right: 399px;
  z-index: 399;
}

[dir='rtl'] .map {
  right: 0;
  left: 399px;
}

@media screen and (max-width: 899px),
screen and (max-height: 500px) {
  .map {
    z-index: 0;
    right: 0;
    top: 83px;
    overflow: hidden !important;
    min-height: calc(100% - 83px);
    width: 100vw;
  }
}
</style>

<style lang="scss">
[dir='rtl'] .leaflet-control {
  margin-right: 10px;
}

.leaflet-container .leaflet-control-attribution {
  font-size: 10px;
}

.easy-button-button .button-state {
  margin-top: .4vh;
}

@media screen and (max-width: 540px) {
  .leaflet-bottom.leaflet-left {
    bottom: 25px;
  }
}
</style>
