/* eslint-disable */

// resolves relation to the country per and name
const resolveRelation = (obj) => {
    // relationDB = [{"date":"1948-05-12","countries":[{name:"no_mans_land","color":"neutral_zone","effect":"stripes_neutral"}]}]
    const country = obj

    return {
        color: parseZone(country.color),
        effect: country.effect,
        name: country.name,
        date: obj.date
    };
}

const resolveDate = (relationDB, perDate) => {
    const borderDateObj = relationDB.find(element => element.date == perDate)?.countries;
    return borderDateObj;
}

const parseZone = (zone) => {

    const colors = {
        jews: '#296d98',
        jews_puppet: '#3792cb',
        jews_occupied: '#45b6fe',
        uk: '#b30000',
        uk_puppet: 'red',
        uk_occupied: '#ff7f7f',
        arabs: 'green',
        arabs_puppet: '#66a103',
        arabs_occupied: '#97f000',

        //1956 war allies, ie UK and France
        allies: '#b30000',
        allies_puppet: 'red',
        allies_occupied: '#ff7f7f',
        
        //Global
        neutral: '#ffad46',
        neutral_zone: 'transparent',
        default: '#ffad46',
    }


    return colors[zone];
}

module.exports.parse = (data, relationDB, perDate) => {
    /* Input: [{ "country": { "name": "arab_salvation_army", "id": "03888c4a-8ce4-4513-8be2-c01cc6b9f501"}, "geometry": {"coordinates": []],"type": "Polygon"},"_id": "622240222c2902ba125a970d","date": "1948-05-11"}] */
    /* Output: { "type": "FeatureCollection", "features": [{"type": "Feature", "properties": {"country": "arab_salvation_army", "date": "1948-05-11"}, "geometry": {"type": "Polygon", "coordinates": []}}]} */

    let searchFor = resolveDate(relationDB, perDate);
    if (!searchFor) return [];
    /* SearchFor [{name:"county_name","borderDate":"1948-05-11","color":""}] */
    let features = [];
    data.forEach(element => {

        //check if country is in searchFor and if so push it to features
        let current = searchFor.find(e => (e.name === element.country.name) && (e.borderDate === element.date));
        if (!current) return;

        //parse the color
        const parsedRelation = resolveRelation(current);
        const feature = {
            type: "Feature",
            properties: {
                id: element?.country?.id,
                color: parsedRelation.color,
                effect: parsedRelation.effect,
                country: element?.country?.name,
                pane: current.color,
                date: element?.date,
            },
            geometry: {
                type: "Polygon",
                coordinates: element?.geometry?.coordinates
            }
        }

        //push the feature
        const featureObj = {
            type: "FeatureCollection",
            features: [feature]
        }

        features.push(feature);
    });

    return features;
}
