import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import moment from 'moment-timezone'

export default createStore({
  plugins: [createPersistedState()],
  state: {
    date: '1948-05-11', //1956-10-24
    war: '1948-arab-israeli-war', //Suez Crisis
    sidebar: 'info',
    resize: 0,
    ready: false,
    geo: false,
    marker: true,
    place: true,
    dark: false,
    view: 'sidebar',
    new: true,
    locale: 'en',
  },
  mutations: {
    setDate(state, date) {
      state.date = date;
    },
    nextDate(state) {
      const currWar = state.war
      const date = moment.tz(state.date, "Asia/Jerusalem")
      date.add(1, 'days')
      // check if date is in range
      if (currWar === '1948-arab-israeli-war' && date.valueOf() > moment.tz('1949-07-20', "Asia/Jerusalem").valueOf()) {
        return;
      } else if (currWar === 'suez-crisis' && date.valueOf() > moment.tz('1956-11-07', "Asia/Jerusalem").valueOf()) {
        return;
      }
      state.date = date.format().slice(0, 10);
    },
    backDate(state) {
      const currWar = state.war
      const date = moment.tz(state.date, "Asia/Jerusalem")
      date.add(-1, 'days')
      // check if date is in range
      if (currWar === '1948-arab-israeli-war' && date.valueOf() < moment.tz('1948-05-11', "Asia/Jerusalem").valueOf()) {
        return;
      } else if (currWar === 'suez-crisis' && date.valueOf() < moment.tz('1956-10-27', "Asia/Jerusalem").valueOf()) {
        return;
      }
      state.date = date.format().slice(0, 10);
    },
    setSidebar(state, sideBar) {
      state.sidebar = sideBar;
    },
    resize(state) {
      state.resize++;
    },
    toggleGeo(state) {
      state.geo = !state.geo;
    },
    toggleMarker(state) {
      state.marker = !state.marker;
    },
    togglePlace(state) {
      state.place = !state.place;
    },
    toggleTheme(state) {
      state.dark = !state.dark;
    },
    setView(state, view) {
      state.view = view;
    },
    // user visits the page for the first time
    setNew(state, bool = false) {
      state.new = bool;
    },
    setReady(state, bool = false) {
      state.ready = bool;
    },
    setLocale(state, locale) {
      state.locale = locale;
      switch (navigator.language) {
        case 'he-IL':
          state.locale === 'he' ? state.locale = 'he' : state.locale = locale;
          break;
        default:
          state.locale === 'en' ? state.locale = 'en' : state.locale = locale;
          break;
      }
    },
    changeConflict(state, war) {
      state.war = war;
      switch (state.war) {
        case '1948-arab-israeli-war':
          state.war === '1948-arab-israeli-war' ? state.war === '1948-arab-israeli-war' : state.war = war;
          state.date = '1948-05-11';
          break;
        case 'suez-crisis':
          state.war === 'suez-crisis' ? state.war = 'suez-crisis' : state.war = war;
          state.date = '1956-10-27';
          break;
        default:
          state.war = war;
      }
    },
  },
});
