<template>
  <div>
    <!-- The Modal -->
    <div id="modal" class="modal" v-if="$store.state.new">
      <!-- Modal content -->
      <div class="modal-content">
        <!-- 2 columns -->
        <div class="modal-body" :class="[ $store.state.locale == 'he' || 'ar' ? 'modal-rtl' : 'row' ]">
          <div class="col-md-7 col-xs-12">
            <!--Title + Description-->
            <h3 style="font-family: 'Bebas Neue', cursive; font-size: 40px" :class="[ $store.state.locale == 'he' || $store.state.locale == 'ar' ? 'text-right' : 'text-left' ]">{{ $t('modal.header') }}</h3>
            <p class="overflow-auto" :class="[ $store.state.locale == 'he' || $store.state.locale == 'ar' ? 'text-right' : 'text-left' ]" style="font-size: 20px; font-family: 'Roboto', sans-serif">
              {{ $t('modal.textOne') }}<b>{{ $t('modal.projectWorldAtWar') }}</b>{{ $t('modal.textTwo') }}<b>{{ $t('modal.projectWorldAtWar') }}</b>{{ $t('modal.textThree') }}<br /><br />

              {{ $t('modal.textFour') }}<br /><br />

              {{ $t('modal.textFive') }}<br />
              {{ $t('modal.textSix') }}<br />
              {{ $t('modal.textSix2') }}<br />
              {{ $t('modal.textSeven') }}<br /><br />

              {{ $t('modal.textEight') }}<b>support@projectwaw.org</b>{{ $t('modal.textNine') }}<a href="https://discord.gg/dU84EgXu2T">{{ $t('modal.textTen') }}</a>{{ $t('modal.textEleven') }}<br /><br />

              {{ $t('modal.textTwelve') }}
            </p>
            <button type="button" :disabled="!$store.state.ready" class="btn btn-primary btn-lg btn-block hidden-lg hidden-md hidden-xl" @click="$store.commit('setNew')">
              {{ $store.state.ready ? $t('modal.continue') : $t('modal.loading') }}
            </button>
          </div>
          <!-- /.col-sm-6 -->
          <div class="col-md-5 col-xs-3 hide-mobile">
            <!-- Centered image -->
            <img class="img-responsive center-block hidden-xs hidden-s" src="../../public/WAW_Logo.webp" alt="" />
            <!-- Continue button -->
            <button type="button" :disabled="!$store.state.ready" class="btn btn-primary btn-lg btn-block hidden-none hidden-xs" @click="$store.commit('setNew')">
              <img v-if="!$store.state.ready" width="40" src="../../public/tank.gif" alt="" />
              {{ $store.state.ready ? $t('modal.continue') : $t('modal.loading') }}</button>
          </div>
          <!-- /.col-sm-6 -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto&display=swap');

/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.4); /* Fallback colour: Black w/ opacity */
}

.img-responsive {
  padding: 25px;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  width: 85%; /* Could be more or less, depending on screen size */
  margin: 5% auto; /* 15% from the top and centered */
  margin-top: calc(5% - 20px);
  margin-bottom: calc(4.4% - 20px);
  padding: 20px;
  border: 1px solid #888;
}

.modal-content a {
  text-decoration: underline;
}

@media screen and (max-width: 990px) {
  .hide-mobile {
    display: none;
  }
}

.modal-rtl {
  display: flex;
  flex-direction: row;
}
</style>
