
import { Options, Vue } from 'vue-class-component';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/src/jquery.js';

import 'bootstrap/dist/js/bootstrap.min.js';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faDiscord,
  faPatreon,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import Map from '@/components/Map.vue';
import Sidebar from '@/components/Sidebar.vue';
import modal from '@/components/Modal.vue';

library.add(faPatreon);
library.add(faTwitter);
library.add(faDiscord);

@Options({
  components: {
    Map,
    Sidebar,
    modal,
  },
})
export default class Home extends Vue {}
