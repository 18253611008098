let locales;
let layout;

module.exports._ = (db) => {
  /* {"location":[32.95749409,35.17168591],"_id":"671","district":"Acre","place":"Abu Sinan","arabs_pop":820,"total_pop":820,"arabs_land":12871,"public_land":172,"total_land":13043,"date_occupied":"/ or ?","date_depopulated":"/ or ?","reason_depopulated":"/ or ?"} */
  /*  //sort by total_pop
  db.sort((a, b) => {
    return (Number(a.total_pop) || 0) - (Number(b.total_pop) || 0);
  }); */
  return db.map((place, index) => {
    /* Color property based on the place's population blue is more then 70% jewish green is more then 70% arabs and yellow is default */
    /* Radius property based on the place's population */
    // convert all numbers
    place.arabs_popnum = Number(place.arabs_pop) || 0;
    place.jews_popnum = Number(place.jews_pop) || 0;
    place.total_popnum = Number(place.total_pop) || 0;
    place.arabs_landnum = Number(place.arabs_land) || 0;
    place.jews_landnum = Number(place.jews_land) || 0;
    place.public_landnum = Number(place.public_land) || 0;
    place.total_landnum = Number(place.total_land) || 0;

    function formatNumber(num) {
      // format number with comma like 1,000
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    function formatDate(d) {
      // out: Month Year in: 1948-05
      const date = new Date(d);
      if (isNaN(date)) {
        return '?';
      }
      else {
        // months array

        const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const day = date.getDate();
        const month = MONTHS[date.getMonth()];
        const year = date.getFullYear();

        return `${(day != 1) ? day : ''} ${month} ${year}`;
      }
    }
    let radius = (Number(place.total_popnum) || 0) * 0.005;
    radius = radius < 50 ? 50 : radius;
    let color = 'yellow';
    if (place.arabs_popnum > place.total_popnum * 0.7) {
      color = 'green';
    }
    else if (place.jews_popnum > place.total_popnum * 0.7) {
      color = 'blue';
    }
    if ((Number(place.total_popnum) || 0) == 0) {
      color = 'grey';
    }
    const popup = {};
    const arabs_pop = ((place.arabs_pop / place.total_pop) * 100).toFixed(2);
    const jews_pop = ((place.jews_pop / place.total_pop) * 100).toFixed(2);
    // land
    const arabs_land = ((place.arabs_land / place.total_land) * 100).toFixed(2);
    const jews_land = ((place.jews_land / place.total_land) * 100).toFixed(2);
    const public_land = ((place.public_land / place.total_land) * 100).toFixed(2);
    const chartPop = `
    <div class="pie" style="--size: 100;">
    <div class="pie__segment" style="--offset: ${0}; --value: ${arabs_pop || 0}; --bg: #2ecc71; ${arabs_pop > 50 ? '--over50:1;' : ''}"></div>
    <div class="pie__segment" style="--offset: ${arabs_pop || 0}; --value: ${jews_pop}; --bg: #22a7f0; ${jews_pop > 50 ? '--over50:1;' : ''}"></div>
    </div>
    `;
    const offset = parseFloat(arabs_land) + parseFloat(jews_land);
    const chartLand = `
    <div class="pie" style="--size: 100;">
    <div class="pie__segment" style="--offset: ${0}; --value: ${arabs_land}; --bg: #2ecc71; ${arabs_land > 50 ? '--over50:1;' : ''}"></div>
    <div class="pie__segment" style="--offset: ${0 + Number(arabs_land)}; --value: ${jews_land}; --bg: #22a7f0; ${jews_land > 50 ? '--over50:1;' : ''}"></div>
    <div class="pie__segment" style="--offset: ${offset}; --value: ${public_land}; --bg: #db0a5b; ${public_land > 50 ? '--over50:1;' : ''}"></div>
    </div>
    `;

    const chart = `<div class="chart">
    <h5 style="text-align:center;">${locales['population']}</h5>
    ${chartPop}
    </div>
    <div class="chart">
    <h5 style="text-align:center;">${locales['land_ownership']}</h5>
    ${chartLand}
    </div>`;

    popup.ltr = `
    <div class="popupplace row">
    <div class="col-md-6">
    <b style="font-size:20px;margin:0">${place.place}</b></br>
    <b>${locales['district']}</b>: ${place.district}</br>
    <strong style="font-size:14px;">${locales['population']}</strong><br>
    <b>${locales['arab']}</b>: ${formatNumber(place.arabs_pop || '?')}<br>
    <b>${locales['jews']}</b>: ${formatNumber(place.jews_pop || '?')}<br>
    <b>${locales['total']}</b>: ${formatNumber(place.total_pop || '?')}<br>
    <strong style="font-size:14px;">${locales['land_ownership']}</strong><br>
    <b>${locales['arab']}</b>: ${formatNumber(place.arabs_land || '?')} ${locales['dunams']}<br>
    <b>${locales['jews']}</b>: ${formatNumber(place.jews_land || '?')} ${locales['dunams']}<br>
    <b>${locales['public']}</b>: ${formatNumber(place.public_land || '?')} ${locales['dunams']}<br>
    <b>${locales['total']}</b>: ${formatNumber(place.total_land || '?')} ${locales['dunams']}<br>
    <strong style="font-size:14px;">${locales['occupation']}</strong><br>
    <b>${locales['date_occupied']}</b>: <i>Coming soon</i><br>           
    <b>${locales['date_depopulated']}</b>: ${formatDate(place.date_depopulated)}<br>
    <b>${locales['reason_depopulated']}</b>: ${place.reason_depopulated}<br>
    </div>
    <div class="col-md-5 mt-1 float-right">
    <br>
    ${Number(place.total_pop) > 0 ? chart : ''}
    </div>
    </div>
   `;
    popup.rtl = `
        <div class="popup">
    `;
    return {
      id: place._id,
      tooltip: place.place,
      location: place.location,
      district: place.district,
      radius: radius,
      color: color,
      popup: popup[layout],
    };
  });
};

module.exports.cache = (locale, _layout) => {
  locales = locale;
  layout = _layout;
};
