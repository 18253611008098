module.exports = (date, db) => {
  /*
        Sample
        {
            "_id": "e523c731-aa26-420c-b37b-d7234286ed87",
            "country": "5cf7b120-cd0c-4db0-9f75-c88f8991ceec",
            "cssClass": "fas fa-info",
            "date": "1936-07-17",
            "location": [28.472917, -16.262512],
            "marker": "black_info",
            "pageWeight": 1,
            "source": "d9035b1e-958e-4959-8ef6-24c092447e48",
            "text": "Franco arrives at dawn in Las Palmas with his wife and daughter, under the pretext of attending General Balmes' funeral at 8:30 AM."
        },
  */
  // sort by location
  db = db.sort((a, b) => {
    return b.location[0] - a.location[0];
  });

  db = db.filter((item) => {
    return item.date === date;
  });

  return db.map((marker, index) => {
    return {
      id: marker._id,
      category: marker.category,
      country: marker.country,
      cssClass: marker.cssClass,
      date: marker.date,
      location: marker.location,
      marker: marker.marker,
      pageWeight: marker.pageWeight,
      source: marker.source,
      text: marker.text,
      text_he: marker.text_he,
      text_ar: marker.text_ar,
      text_hr: marker.text_hr
    };
  });
};
