import React from 'react';
import { KeyRowModel } from '@/models/sidebar/keyRow.model';

export function KeyRow(props: KeyRowModel): JSX.Element {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0.5em' }}>
      <div style={{ float: 'left', width: '50px', height: '40px' }}>
        <div style={{ backgroundColor: props.color, height: '100%', borderRadius: '5px' }}></div>
      </div>
      <div className="key-description">
        <span style={{ marginLeft: '10px', marginRight: '10px' }}>- { props.territory }</span>
      </div>
    </div>
  );
}