/**
 *
 * @param {*} obj object
 * @param {*} keyName key name
 * @param {*} lang  locale lang
 * @returns
 */
module.exports.mapByKey = function(obj, keyName, lang) {
  if (lang != 'en' && obj?.[keyName + '_' + lang]) {
    return obj[keyName + '_' + lang];
  }
  return obj?.[keyName];
};
