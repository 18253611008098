import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone'
import '@/styles/dateChanger.css';

// I put the interface here and not in a different file because for some reason it says that the backDate and nextDate functions don't exist when I call them here
// If you know why this could be happening please let me know
export interface DateChangerModel {
    date: string;
    lang: string;
    backDate: (date: string) => any;
    nextDate: (date: string) => any;
}

export function DateChanger(props: DateChangerModel) {
  const { date, lang, backDate, nextDate } = { ...props };

  const [currentDate, setCurrentDate] = useState<string>('');
  const rtl = 'he' || 'ar';

  useEffect(() => {
    dateTranslator();
  }, [date, lang]);

  function moveBackDate() {
    backDate(date);
  }

  function moveNextDate() {
    nextDate(date);
  }

  // The functionality exists in Select.vue as well but it had more stuff going on behind the scenes so I rewrote it to something simpler
  function dateTranslator() {
    const newDate = moment.tz(date, "Asia/Jerusalem")
    const year = date.slice(0, 4);
    const month = newDate.format('MMMM').toLocaleString();
    const day = date.slice(8, 10);
    setCurrentDate(`${day} ${month} ${year}`);
  }

  return (
    <div className='container-spanner' id='containerSpanner'>
      <div className='date-changer-container'>
        <h4 className='text-container'>
          <i className={lang === rtl ? 'fas fa-chevron-right' : 'fas fa-chevron-left'} onClick={moveBackDate} />
          <div className='date-header'>{currentDate}</div>
          <i className={lang === rtl ? 'fas fa-chevron-left' : 'fas fa-chevron-right'} onClick={moveNextDate} />
        </h4>
      </div>
    </div>
  );
}