import { KeyRowModel } from '@/models/sidebar/keyRow.model';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyBlock } from './keyBlock';

export function Territories1956(): ReactNode {
  const { t } = useTranslation();

  const neutralKeys: KeyRowModel[] = [
    { color: '#FFAD46', territory: t('keys.territory.neutral_countries') }
  ];

  const arabKeys: KeyRowModel[] = [
    { color: '#008000', territory: t('keys.territory.egypt') },
    { color: '#66a103', territory: t('keys.territory.egypt_puppets') }
  ];

  const jewKeys: KeyRowModel[] = [
    { color: '#296D98', territory: t('keys.territory.israel') },
    //{ color: '#3792CB', territory: t('keys.territory.jews_puppets') },
    { color: '#45B6FE', territory: t('keys.territory.israel_occupied_territory') },
  ];

  const ukKeys: KeyRowModel[] = [
    { color: '#b30000', territory: t('keys.territory.uk_cyprus') },
    { color: '#ff7f7f', territory: t('keys.territory.anglo_french_egypt') },
  ];

  return (
    <div style={{ padding: '1em', paddingBottom: '0' }}>
      <h4><b>{ t('keys.title') }</b></h4>
      <hr className='info-divider'></hr>
      <h5
        className="territories"
        style={{
          fontSize: '18px',
          textAlign: 'center'
        }}>
        { t('keys.territories') }
      </h5>
      <br />
      <KeyBlock header={ t('keys.countries.neutral') } rows={neutralKeys}/>
      <KeyBlock header={ t('keys.countries.egypt')} rows={arabKeys}/>
      <KeyBlock header={ t('keys.countries.israel')} rows={jewKeys}/>
      <KeyBlock header={ t('keys.countries.uk_and_france')} rows={ukKeys}/>
    </div>
  );
}