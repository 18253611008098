
import { Options, Vue } from 'vue-class-component';
import Navbar from '@/components/Navbar.vue';

@Options({
  components: {
    Navbar,
  },
  mounted() {
    this.$store.commit('setNew', true);
    this.$store.commit('setReady');
  },
  created() {
    const scripts = ['https://kit.fontawesome.com/02faa02085.js'];
    scripts.forEach((script) => {
      const tag = document.createElement('script');
      tag.setAttribute('src', script);
      document.head.appendChild(tag);
    });
  },
})
export default class App extends Vue {}
