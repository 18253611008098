import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { IconKey } from './iconKey';
import { IconKeyModel } from '@/models/sidebar/iconKey.model';

export function ArrowBlock(): ReactNode {
  const { t } = useTranslation();

  const arrows: IconKeyModel[] = [
    { icon: 'fa-arrow-right', name: t('keys.icons.40_commando'), color: '#2ecc71', customIcon: false },
    { icon: 'fa-arrow-right', name: t('keys.icons.42_commando'), color: '#e74c3c', customIcon: false },
    { icon: 'fa-arrow-right', name: t('keys.icons.45_commando'), color: '#3498db', customIcon: false }
  ];

  return (
    <div style={{padding:'1em', paddingTop:'0'}}>
      <hr className='info-divider'></hr>
      <h4 style={{marginTop:'1em'}}>{ t('keys.movement') }</h4>
      {arrows.map(i =>
        <IconKey key={arrows.indexOf(i)} icon={i.icon} color={i.color} name={i.name} customIcon={i.customIcon} />
      )}
    </div>
  );
}