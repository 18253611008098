import React from 'react';
// import { IconKeyModel } from '@/models/sidebar/iconKey.model';

import '@/assets/fonts/customIcon.css';
import { ArtilleryLeftIcon, ArtilleryRightIcon, BombIcon, GunLeftIcon, GunRightIcon, TankLeftIcon, TankRightIcon } from '../icons';

export interface IconKeyModel {
  customIcon: boolean;
  icon: string;
  name: string;
  color?: string;
}

export function IconKey(props: IconKeyModel): JSX.Element {
  function getIconCode(icon: string, color: string): JSX.Element {
    switch (icon) {
      case 'artillery-left':
        return <ArtilleryLeftIcon width={45} height={45} color={color} />;
      case 'artillery-right':
        return <ArtilleryRightIcon width={45} height={45} color={color}/>;
      case 'bomb':
        return <BombIcon width={45} height={45} color={color}/>;
      case 'gun-left':
        return <GunLeftIcon width={45} height={45} color={color}/>;
      case 'gun-right':
        return <GunRightIcon width={45} height={45} color={color}/>;
      case 'tank-left':
        return <TankLeftIcon width={45} height={45} color={color}/>;
      case 'tank-right':
        return <TankRightIcon width={45} height={45} color={color}/>;
    }
    return <></>;
  }

  const iconElement = (
    <>
      {props.customIcon && props.icon && props.color // Check if props.icon and props.color are defined
        ? getIconCode(props.icon, props.color)
        : <i className={`fas ${props.icon} icon`} style={{ color: props.color || '#2c3e50' }} />
      }
      <div style={{ marginLeft: '10px', marginRight: '10px' }}>
        - {props.name}
      </div>
      <br />
    </>
  );
  
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0.5em'
    }}>
      {iconElement}
    </div>
  );
}