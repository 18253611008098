import { Divider } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function CopyrightBlock(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div style={{ padding:'1em' }}>
      <Divider>{ t('keys.copyright.header') }</Divider>
      <a href="https://fontawesome.com/" target="_blank">https://fontawesome.com/</a>&nbsp;
      { t('keys.copyright.copyright1') }
      <br />
      <a href="http://cliparts101.com/" target="_blank">http://cliparts101.com/</a>&nbsp;
      { t('keys.copyright.copyright2') }
      <br />
      <a href="http://getdrawings.com/" target="_blank">http://getdrawings.com/</a>&nbsp;
      { t('keys.copyright.copyright3') }
      <br />
      <a href="https://creazilla.com/" target="_blank">https://creazilla.com/</a>&nbsp;
      { t('keys.copyright.copyright4') }
      <br />
      { t('keys.copyright.copyright5') }
    </div>
  );
}