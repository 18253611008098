<template>
  <div class="tabcontent">
    <h4 id="date3_info">
      <b>{{ parseDate($store.state.date) }}</b>
    </h4>
    <hr class="info-divider" />
    <div v-if="!!infos && infos.length">
      <!-- es-lint-disable -->
      <template v-for="(o, idx) in infos" :key="o._id + idx">
        <InfoCard
        :id="o._id"
        :country="o.country"
        :content="o.text"
        :flag="o.flag"
        :location="o.location"
        :icon="o.cssClass"
        :source="o.source"
        :sources="sources"
        :mapFunction="setMapView"></InfoCard>
        <hr class="info-divider" />
      </template>
    </div>
  </div>
</template>

<script>
/* import config from '@/config'; */
import moment from 'moment-timezone'

import db1948 from '@/helpers/blob-1948.json';
import db1956 from '@/helpers/blob-1956.json';

import { mapByKey } from '@/helpers/langMapping.js';
import { applyPureReactInVue } from 'veaury';
import { InfoCard } from './infoCard.tsx';

export default {
  name: 'info',
  data() {
    return {
      dbSources: [],
      geos: [],
      events: [],
      dbCountries: [],
      dates: [],
      infos: [],
      countries: [],
      sources: [],
    };
  },
  components: {
    InfoCard: applyPureReactInVue(InfoCard)
  },
  methods: {
    checkCurrentWar: function () {
      switch (this.$store.state.war) {
        case '1948-arab-israeli-war':
          this.dbSources = db1948.sources;
          this.geos = db1948.geo;
          this.events = db1948.events;
          this.dbCountries = db1948.countries;
          this.dates = db1948.dates;
          this.categories = db1948.categories;
          break;
        case 'suez-crisis':
          this.dbSources = db1956.sources;
          this.geos = db1956.geo;
          this.events = db1956.events;
          this.dbCountries = db1956.countries;
          this.dates = db1956.dates;
          this.categories = db1956.categories;
          break;
      }
    },
    parseDate(date) {
      const newDate = moment.tz(date, "Asia/Jerusalem")
      const day = newDate.date();
      const mapLocales = {
        en: 'en-en',
        he: 'he-IL-u-nu-hebr',
        ar: 'ar-EG-u-nu-latn',
        hr: 'hr-hr',
      };
      const month = newDate.format('MMMM').toLocaleString(mapLocales[this.$store.state.locale], { month: 'long' });
      const year = newDate.year();
      return `${day} ${month} ${year}`;
    },
    async renderInfo() {
      /* const res = await fetch(
        config.api + '/events/date/' + this.$store.state.date
      );
      this.infos = await res.json(); */
      this.infos = this.events.filter((e) => e.date === this.$store.state.date);
      this.infos.map((element) => {
        const current = this.countries.find((country) => country._id === element.country || country.shortName === element.country || country['shortName_' + this.$store.state.locale] === element.country);
        element.country = mapByKey(current, 'shortName', this.$store.state.locale) || mapByKey(current, 'name', this.$store.state.locale);
        element.flag = current?.flag;
        element.text = mapByKey(element, 'text', this.$store.state.locale);
      });
      // sort after pageWeight
      this.infos.sort((eventA, eventB) => {
        return eventA.pageWeight - eventB.pageWeight;
      });
      this.categories && this.categories.sort((catA, catB) => {
        return catA.id - catB.id;
      });
    },
    setMapView(location) {
      window.map.setView([`${location[0]}`, `${location[1]}`]);
    },
  },
  async mounted() {
    this.checkCurrentWar();
    // const res3 = await fetch(config.api + '/countries');
    // this.countries = await res3.json();
    this.countries = this.dbCountries;
    
    // const res4 = await fetch(config.api + '/sources');
    // this.sources = await res4.json();
    this.sources = this.dbSources;

    await this.renderInfo();

    this.$store.watch(
      (state) => {
        return this.$store.state.date;
      },
      async () => {
        await this.renderInfo();
      },
    );
  },
};
</script>

<style>
  .info-divider {
    border-top: 1px solid #a9a9a9;
    margin-top: 18px;
    margin-bottom: 18px;
  }
</style>
