<template>
  <div
    id="sidebar"
    :style="{ display: $store.state.view == 'sidebar' ? 'block' : 'none' }"
    class="sidebar"
  >
    <div class="sidebar-nav">
      <ul class="nav navbar-nav sidebar-links-nav">
        <li>
          <a
            id="info-button"
            class="sidebar-links"
            href="#"
            @click="setSideBar('info')"
            :class="$store.state.sidebar == 'info' ? ' mobile-active' : ''"
            >{{ $t('sidebar.info') }}</a
          >
        </li>
        <li>
          <a
            id="date-button"
            class="sidebar-links"
            href="#"
            @click="setSideBar('date')"
            :class="$store.state.sidebar == 'date' ? ' mobile-active' : ''"
          >
            {{ $t('sidebar.date') }}</a
          >
        </li>
        <li style="width: 34%">
          <a
            id="keys-button"
            class="sidebar-links"
            href="#"
            @click="setSideBar('keys')"
            :class="$store.state.sidebar == 'keys' ? ' mobile-active' : ''"
          >
            {{ $t('sidebar.keys') }}</a
          >
        </li>
      </ul>
    </div>
    <div class="sidebar-bottom-nav">
      <ul class="nav navbar-nav sidebar-bottom-links-nav">
        <li style="background-color: #7289da">
          <a id="discord-button" href="https://discord.gg/qftqDpY" target="_blank"
            ><font-awesome-icon :icon="['fab', 'discord']" />
            {{ $t('sidebar.discord') }}</a
          >
        </li>
        <li style="background-color: #f96854">
          <a id="patreon-button" href="https://www.patreon.com/projectwaw" target="_blank"
            ><font-awesome-icon :icon="['fab', 'patreon']" />
            {{ $t('sidebar.patreon') }}</a
          >
        </li>
        <li style="width: 34%; background-color: #1da1f2">
          <a id="twitter-button" href="https://twitter.com/ProjectWAW" target="_blank"
            ><font-awesome-icon :icon="['fab', 'twitter']" />
            {{ $t('sidebar.twitter') }}</a
          >
        </li>
      </ul>
    </div>
    <template class="tabcontent" v-if="!!$store.state.sidebar">
      <KeepAlive>
        <Info v-if="$store.state.sidebar == 'info'"></Info>
      </KeepAlive>
      <Date v-if="$store.state.sidebar == 'date'"></Date>
      <Territories1948 v-if="$store.state.sidebar == 'keys' && $store.state.war == '1948-arab-israeli-war'" />
      <Territories1956 v-if="$store.state.sidebar == 'keys' && $store.state.war == 'suez-crisis'" />
      <ArrowBlock v-if="$store.state.sidebar == 'keys' && $store.state.war == 'suez-crisis'" />
      <IconBlock v-if="$store.state.sidebar == 'keys'" />
      <CopyrightBlock v-if="$store.state.sidebar == 'keys'" />
    </template>
  </div>
</template>

<script>
import Date from '@/components/Sidebar/Date.vue';
import Info from '@/components/Sidebar/Info.vue';
import { applyPureReactInVue } from 'veaury';
import { IconBlock } from '@/components/Sidebar/mapKeys/iconBlock.tsx';
import { ArrowBlock } from '@/components/Sidebar/mapKeys/arrowBlock.tsx';
import { CopyrightBlock } from '@/components/Sidebar/mapKeys/copyrightBlock.tsx';

import { Territories1948 } from '@/components/Sidebar/mapKeys/territories_1948';
import { Territories1956 } from '@/components/Sidebar/mapKeys/territories_1956';

export default {
  name: 'Sidebar',
  components: {
    Date,
    Info,
    Territories1948: applyPureReactInVue(Territories1948),
    Territories1956: applyPureReactInVue(Territories1956),
    ArrowBlock: applyPureReactInVue(ArrowBlock),
    IconBlock: applyPureReactInVue(IconBlock),
    CopyrightBlock: applyPureReactInVue(CopyrightBlock)
  },
  methods: {
    setSideBar(state) {
      this.$store.commit('setSidebar', state);
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  -webkit-box-shadow: 0 0 0;
  box-shadow: 0 0 0;
}
.nav > li > a:hover,
.nav > li > a:focus {
  background-color: transparent;
}

.mobile-active {
  border-bottom: 2px solid #6b553b !important;
}

#sidebar {
  width: 399px;
  position: fixed;
  overflow-y: auto;
  right: 0;
  bottom: 50px;
  top: 100px;
  background-color: #fafaf5;
}

[dir='rtl'] #sidebar {
  right: auto;
  left: 0;
}

#sidebar ul {
  height: 50px;
}
#sidebar ul li {
  float: left;
  width: 33%;
  text-align: center;
}
#sidebar ul li a {
  height: 50px;
  line-height: 22px;
  color: #ffffff;
}
.sidebar-links-nav {
  background-color: #bba58e;
  margin: 0;
  display: flex;
  width: 399px;
}

[dir='rtl'] .sidebar-links-nav .sidebar-bottom-links-nav {
  direction: rtl;
}

[dir='rtl'] ul {
  padding-right: 0;
  padding-inline-start: 0 !important;
}

.sidebar-links {
  border-bottom: 2px solid #e7e7e7;
  background-color: #bba58e;
}
.sidebar-links:focus,
.sidebar-links:active,
.sidebar-links:hover {
  outline: none;
}
.sidebar-nav {
  position: -webkit-fixed;
  position: fixed;
  top: 50px;
  width: 399px;
  z-index: 800;
  box-sizing: border-box;
}
.sidebar-bottom-nav {
  position: -webkit-fixed;
  position: fixed;
  bottom: 0px;
  width: 399px;
  z-index: 800;
  box-sizing: border-box;
}
.sidebar-bottom-links-nav {
  display: flex;
  width: 100%;
  line-height: normal;
}
hr {
  height: 1px;
  background-color: darkgray;
  border: none;
  margin-top: 18px;
  margin-bottom: 18px;
}
.key-hr {
  background-color: #dedede;
}
.tabcontent {
  padding: 1em;
}
.MuiTooltip-popper {
  z-index: 1000;
}

@media screen and (max-width: 899px) ,screen and (max-height: 500px) {
  #sidebar {
    top: 130px;
    background-color: #fafaf5;
  }

  #sidebar ul li a {
    line-height: 28px;
  }

  .sidebar-nav {
    top: 80px;
  }

  #sidebar,
  .sidebar-bottom-links-nav,
  .sidebar-nav,
  .sidebar-bottom-nav,
  .nav,
  .navbar-nav {
    width: 100vw;
    z-index: 2;
  }

  .navbar-nav {
    margin: 0;
  }
}
</style>
