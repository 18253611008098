import { createApp } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from './App.vue';
import router from './router';
import store from './store';
import './i18n-react';
import i18nVue from './i18n-vue';
import PrimeVue from 'primevue/config';
import Dropdown from 'primevue/dropdown';
import 'primevue/resources/themes/viva-dark/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

createApp(App).component('font-awesome-icon', FontAwesomeIcon).component('Dropdown', Dropdown).use(router).use(store).use(i18nVue).use(PrimeVue).mount('#app');
