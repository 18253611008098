module.exports = [
  {
    name: 'stripes_neutral',
    style: {
      weight: 5,
      color: '#ffad46',
      spaceWeight: 5,
      angle: 45,
    },
  },
  {
    name: 'stripes_arabs',
    style: {
      weight: 4,
      color: 'green',
      spaceWeight: 4,
      spaceColor: '#ffad46',
      spaceOpacity: 1,
      angle: 45,
    },
  },
  {
    name: 'stripes_arabs_occupied',
    style: {
      weight: 4,
      color: '#97f000',
      spaceWeight: 4,
      spaceColor: '#ffad46',
      spaceOpacity: 1,
      angle: 45,
    },
  },
  {
    name: 'stripes_gush',
    style: {
      weight: 4,
      color: '#66a103',
      spaceWeight: 4,
      spaceColor: '#97f000',
      spaceOpacity: 1,
      angle: 45,
    },
  },
  {
    name: 'stripes_sinai',
    style: {
      weight: 6,
      color: '#3792cb',
      spaceWeight: 4,
      spaceColor: 'green',
      spaceOpacity: 1,
      angle: 45,
    },
  },
  {
    name: 'dmz',
    style: {
      weight: 4,
      color: 'white',
      spaceWeight: 4,
      spaceColor: 'red',
      spaceOpacity: 1,
      angle: 45,
    },
  },
];
