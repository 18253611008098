let db = [];
let locales = {};
let layout = 'ltr';
let lang = 'en';

/**
 *
 * @param {*} obj object
 * @param {*} keyName key name
 * @param {*} lang  locale lang
 * @returns
 */
const _ = function(obj, keyName) {
  if (lang != 'en' && obj?.[keyName + '_' + lang]) {
    return obj[keyName + '_' + lang];
  }
  return obj?.[keyName];
};

module.exports._ = (feature, layer) => {
  const country = db.find((e) => e._id == feature.properties.id);
  layer.tag = 'geo';
  if (!country) {
    return;
  }
  // <img class='popupflag' src='"+flag+"' alt='404'><b style='font-size:17px;'>"+name+"</b><br><b>Status</b>: "+status+"<br><b>Government</b>: "+government+"<br><b>Capital</b>: "+capital+"<br><b>Ruling Party</b>: "+party+"<br><b>Head of State</b>: "+hos+"<br><b>Head of Government</b>: "+hog+""
  const popup = {};
  popup.ltr = `
    <img class='popupflag' src='${country?.flag}' alt='404'>
    <b style='font-size:20px;'>${country?.name}</b><br>
    <b>${locales['status']}</b>: ${country?.status}<br>
    <b>${locales['government']}</b>: ${country?.government}<br>
    <b>${locales['capital']}</b>: ${country?.capital}<br>
    <b>${locales['ruling_party']}</b>: ${country?.party}<br>
    <b>${locales['head_of_state']}</b>: ${country?.headOfState}<br>
    <b>${locales['head_of_government']}</b>: ${country?.headOfGovernment}
  `;

  popup.rtl = `
    <img class='popupflag' src='${country?.flag}' alt='404'>
    <div>
    <b style='font-size:20px;'>${_(country, 'name')}</b><br>
    <b>${locales['status']}</b>: ${_(country, 'status')}<br>
    <b>${locales['government']}</b>: ${_(country, 'government')}<br>
    <b>${locales['capital']}</b>: ${_(country, 'capital')}<br>
    <b>${locales['ruling_party']}</b>: ${_(country, 'party')}<br>
    <b>${locales['head_of_state']}</b>: ${_(country, 'headOfState')}<br>
    <b>${locales['head_of_government']}</b>: ${_(country, 'headOfGovernment')}
    </div>
    `;

  layer.bindPopup(popup[layout]);
};

module.exports.cache = (data, locale, l, _layout) => {
  db = data;
  locales = locale;
  lang = l;
  layout = _layout;
};
