<template>
  <div class="selectBox">
    <template v-for="(currentYear, index) in getDates()" :key="index">
      <div
        class="selectBox-year"
        @click="selectYear(currentYear.year)"
        :class="{ 'selectBox-active': currentYear.year === year }"
      >
        {{ currentYear.year }}
      </div>
      <template v-for="(currentMonth, indexm) in currentYear.months" :key="indexm">
        <div
          v-if="year === currentYear.year"
          class="selectBox-month"
          :class="{ 'selectBox-active': month === currentMonth.month }"
          @click="selectMonth(currentYear.year, currentMonth.month)"
        >
          {{ currentMonth.clientMonth }}
        </div>
        <template v-for="(currentDay, indexd) in currentMonth.days" :key="indexd">
          <div
            v-if="month === currentMonth.month && year === currentYear.year"
            class="selectBox-day"
            :class="{ 'selectBox-active': day === currentDay }"
            @click="selectDay(currentYear.year, currentMonth.month, currentDay)"
          >
            {{ prettyDay(currentDay) }} - {{ getEventTitle(currentYear.year, currentMonth.month, currentDay) }}
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import moment from 'moment-timezone'

export default {
  props: {
    start: String,
    end: String,
  },
  data() {
    return {
      year: null,
      month: null,
      day: null,
      unexpand: {
        year: null,
        month: null,
        day: null,
      },
    };
  },
  name: 'Select',
  methods: {
    getDates() {
      // get years from range
      // Example: start = 1948, end = 1949
      const currentDate = moment.tz(this.$store.state.date, "Asia/Jerusalem")
      console.log(currentDate.format())
      const start = moment.tz(this.start[0], "Asia/Jerusalem")
      const end = moment.tz(this.end[0], "Asia/Jerusalem");

      console.log(start.format(), end.format())

      const years = [];
      for (let i = start.year(); i <= end.year(); i++) {
        const currentYear = {
          year: i,
          months: [],
        };
        const startingMonth = i === start.year() ? start.month() : 0;
        const endingMonth = i === end.year() ? end.month() : 12;
        for (let j = startingMonth; j < endingMonth; j++) {
          const currentMonth = {
            month: new Date(i, j, 1).toLocaleString('en-en', { month: 'long' }),
            clientMonth: new Date(i, j, 1).toLocaleString(`${this.$store.state.locale}-${this.$store.state.locale}`, { month: 'long' }),
            days: [],
          };
          const daysInMonth = new Date(i, j + 1, 0).getDate();
          const startingDay =
            i === start.year() && j === start.month()
              ? start.date()
              : 1;
          const endingDay =
            i === end.year() && j + 1 === end.month()
              ? end.date()
              : daysInMonth;
          for (let k = startingDay; k <= endingDay; k++) {
            currentMonth.days.push(k);
          }
          currentYear.months.push(currentMonth);
        }
        years.push(currentYear);
      }
      return years;
    },
    setUnexpand() {
      this.unexpand.year = this.year;
      this.unexpand.month = this.month;
      this.unexpand.day = this.day;
    },
    resetUnexpand() {
      this.unexpand.year = null;
      this.unexpand.month = null;
      this.unexpand.day = null;
    },
    selectYear(year) {
      console.log(year)
      if (this.year === year) {
        this.setUnexpand();
        this.year = null;
        this.month = null;
        this.day = null;
        return;
      }
      this.year = year;
      this.month = (year === this.unexpand.year) ? this.unexpand.month : null;
      this.day = this.unexpand.day;
      this.resetUnexpand();
    },
    selectMonth(year, month) {
      console.log(year, month)
      if (this.month === month) {
        this.setUnexpand();
        this.month = null;
        this.day = null;
        return;
      }
      this.year = year;
      this.month = month;
      this.day = (year === this.unexpand.year && month === this.unexpand.month) ? this.unexpand.day : null;
      this.resetUnexpand();
    },
    selectDay(year, month, day) {
      console.log(year, month, day)
      this.year = year;
      this.month = month;
      this.day = day;
      // 1948 May 13 1948-05-11
      const m = this.monthToNumber(month);
      const date = new Date(year, m, day + 1);
      console.log(date)
      // date to year-month-day
      const dateString = date.toISOString().split('T')[0];
      this.$store.commit('setDate', dateString);
    },
    initDate() {
      const date = moment.tz(this.$store.state.date, "Asia/Jerusalem");
      if (this.unexpand.year) {
        this.unexpand.year = date.year();
      } else {
        this.year = date.year();
      }
      if (this.unexpand.month) {
        this.unexpand.month = date.format('MMMM').toLocaleString('en-en', { month: 'long' });
      } else {
        this.month = date.format('MMMM').toLocaleString('en-en', { month: 'long' });
      }
      this.day = date.date();
    },
    prettyDay(day) {
      return (day < 10) ? `0${day}` : day;
    },
    monthToNumber(number) {
      const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ];
      return months.indexOf(number);
    },
    getEventTitle(year, month, day) {
      const m = this.monthToNumber(month);
      const date = new Date(year, m, day + 1);
      const dateString = date.toISOString().split('T')[0];
      const title = this.$t(`dateTitle.${dateString}`);
      return title || '';
    },
  },
  mounted() {
    this.initDate();
    this.$store.watch(
      (state) => this.$store.state.date,
      async (newValue, oldValue) => {
        this.initDate();
      }
    );
  },
};
</script>

<style>
.selectBox {
  display: flex;
  margin: 10px;
  margin-top: 75px;
  flex-direction: column;
  text-align: left;
  width: 95%;
  height: 100%;
}

[dir='rtl'] .selectBox {
  text-align: right;
}

.selectBox:hover {
  cursor: pointer;
}

.selectBox-year {
  display: flex;
  flex-direction: column;
  font-weight: normal;
  width: 100%;
  height: 100%;
  padding-left: 5px;
  border-left: rgb(201, 192, 192) solid 6px;
}

.selectBox-month {
  display: flex;
  flex-direction: column;
  font-weight: normal;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  border-left: rgb(201, 192, 192) solid 6px;
}

.selectBox-day {
  display: flex;
  flex-direction: column;
  font-weight: normal;
  width: 100%;
  height: 100%;
  padding-left: 30px;
  border-left: rgb(201, 192, 192) solid 6px;
}

.selectBox-active {
  font-weight: bold;
  border-left: rgb(136, 130, 130) solid 6px;
}

[dir="rtl"] .selectBox-year,
[dir="rtl"] .selectBox-month,
[dir="rtl"] .selectBox-day {
  padding-left: 0;
  border-left: none;
  border-right: rgb(201, 192, 192) solid 6px;
}

[dir='rtl'] .selectBox-year {
  padding-right: 5px;
}

[dir='rtl'] .selectBox-month {
  padding-right: 15px;
}

[dir='rtl'] .selectBox-day {
  padding-right: 30px;
}

[dir='rtl'] .selectBox-active {
  border-left: none;
  border-right: rgb(136, 130, 130) solid 6px;
}
</style>
