import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal = _resolveComponent("modal")!
  const _component_Map = _resolveComponent("Map")!
  const _component_Sidebar = _resolveComponent("Sidebar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_modal),
    _createVNode(_component_Map),
    _createVNode(_component_Sidebar)
  ]))
}