import React from 'react';
import { KeyBlockModel } from '@/models/sidebar/keyBlock.model';
import { KeyRow } from './keyRow';
import { Divider } from '@mui/material';

export function KeyBlock(props: KeyBlockModel): JSX.Element {

  return (
    <div>
      <div style={{ fontSize: '18px', textAlign: 'left', paddingLeft: '10px', paddingBottom: '5px' }}>
        <Divider>{ props.header }</Divider>
      </div>
      {props.rows.map(r =>
        <KeyRow color={r.color} territory={r.territory} />
      )}
    </div>
  );
}