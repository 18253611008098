import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { IconKey } from './iconKey';
// import { IconKeyModel } from '@/models/sidebar/iconKey.model';

export interface IconKeyModel {
  customIcon: boolean;
  icon: string;
  name: string;
  color?: string;
}

export function IconBlock(): ReactNode {
  const { t } = useTranslation();

  const icons: IconKeyModel[] = [
    { icon: 'fa-plane', name: t('keys.icons.air_battle'), customIcon: false, color:'#2c3e50' },
    { icon: 'icon-bomb', name: t('keys.icons.air_raid'), customIcon: false, color:'#2c3e50' },
    { icon: 'fa-ambulance', name: t('keys.icons.ambulance'), customIcon: false, color:'#2c3e50' },
    { icon: 'fa-bullhorn', name: t('keys.icons.announcement'), customIcon: false, color:'#2c3e50' },
    { icon: 'artillery-left', name: t('keys.icons.artillery_attack'), customIcon: true, color:'#2c3e50' },
    { icon: 'fa-crosshairs', name: t('keys.icons.assassination'), customIcon: false, color:'#2c3e50' },
    { icon: 'fa-atom', name: t('keys.icons.atomic_research'), customIcon: false, color:'#2c3e50' },
    { icon: 'fa-drafting-compass', name: t('keys.icons.battle_plans'), customIcon: false, color:'#2c3e50' },
    { icon: 'fa-flag', name: t('keys.icons.city_captured'), customIcon: false, color:'#2c3e50' },
    { icon: 'fa-truck', name: t('keys.icons.convoy'), customIcon: false, color:'#2c3e50' },
    { icon: 'fa-chart-line', name: t('keys.icons.economy'), customIcon: false, color:'#2c3e50' },
    { icon: 'fa-virus', name: t('keys.icons.epidemic'), customIcon: false, color:'#2c3e50' },
    { icon: 'fa-skull-crossbones', name: t('keys.icons.executions'), customIcon: false, color:'#2c3e50' },
    { icon: 'fa-bahai', name: t('keys.icons.explosion'), customIcon: false, color:'#2c3e50' },
    { icon: 'fa-fire', name: t('keys.icons.fire'), customIcon: false, color:'#2c3e50' },
    { icon: 'gun-right', name: t('keys.icons.gun'), customIcon: true, color:'#2c3e50' },
    { icon: 'fa-helicopter', name: t('keys.icons.helicopter'), customIcon: false, color:'#2c3e50' },
    { icon: 'fa-anchor', name: t('keys.icons.naval_battle'), customIcon: false, color:'#2c3e50' },
    { icon: 'fa-binoculars', name: t('keys.icons.reconnaisance'), customIcon: false, color:'#2c3e50' },
    { icon: 'tank-right', name: t('keys.icons.tank_battle'), customIcon: true, color:'#2c3e50' },
    { icon: 'fa-info', name: t('keys.icons.other'), customIcon: false, color:'#2c3e50' },
  ];

  return (
    <div style={{ padding:'1em', paddingTop:'0' }}>
      <hr className='info-divider'></hr>
      <h4 style={{ marginTop:'1em' }}>{ t('keys.event') }</h4>
      {icons.map(i =>
        <IconKey key={icons.indexOf(i)} icon={i.icon} name={i.name} color={i.color}customIcon={i.customIcon} />
      )}
    </div>
  );
}