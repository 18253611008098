<template>
  <div class="tabcontent">
    <h4>
      <b>{{ $t('dates.selection') }}</b>
    </h4>
    <hr class="info-divider">
    <div class="date3-selection">
      <h4>{{ $t('dates.move') }}</h4>
      <div class="date3 move-date3s">
        <div class="date3 date3-change" title="Go one day backwards" id="change-backward" @click="backDate()">
          <i :class="[ $store.state.locale == 'he' || $store.state.locale == 'ar' ? 'fas fa-chevron-right' : 'fas fa-chevron-left']"></i>
        </div>
        <h4 class="date3 date3-info" id="date3_info_2">
          {{ parseDate($store.state.date) }}
        </h4>
        <div class="date3 date3-change" title="Go one day ahead" id="change-forward" @click="nextDate()">
          <i :class="[ $store.state.locale == 'he' || $store.state.locale == 'ar' ? 'fas fa-chevron-left' : 'fas fa-chevron-right']"></i>
        </div>
      </div>
      <DateSelect 
        :start="[this.$store.state.war == '1948-arab-israeli-war' ? '1948-05-11' : '1956-10-27']" 
        :end="[this.$store.state.war == '1948-arab-israeli-war' ? '1949-08-20' : '1956-12-07']">
      </DateSelect>
    </div>
  </div>
</template>

<script>
import DateSelect from '@/components/Sidebar/DateSelect/Select.vue';
import moment from 'moment-timezone'

export default {
  name: 'date',
  emits: ['dateChanged'],
  components: {
    DateSelect,
  },
  methods: {
    parseDate(date) {
      const newDate = moment.tz(date, "Asia/Jerusalem")
      const day = newDate.date();
      const mapLocales = {
        en: 'en-en',
        he: 'he-IL-u-nu-hebr',
        ar: 'ar-EG-u-nu-latn',
        hr: 'hr-hr',
      };
      const month = newDate.format('MMMM').toLocaleString(mapLocales[this.$store.state.locale], { month: 'long' });
      const year = newDate.year();
      return `${day} ${month} ${year}`;
    },
    nextDate() {
      this.$store.commit('nextDate');
    },
    backDate() {
      this.$store.commit('backDate');
    },
  },
};
</script>

<style>
.date3 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.move-date3s {
  border: 2px solid black;
  margin-left: 10%;
  margin-right: 10%;
}

.date3-change {
  flex: 0;
  padding: 5px;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
}

.date3-change:first-child > i {
  margin-right: auto;
}

.date3-change:last-child > i {
  margin-left: auto;
}

.date3-change i {
  font-size: 30px;
}

.date3-change:hover {
  cursor: pointer;
}
.date3-selection {
  justify-content: center;
}

.date3-info {
  font-weight: bold;
  justify-content: center;
}
</style>
